import consumer from "./consumer"

function remove_waiting(upgrade_type) {
    $("[data-upgrade-type='" + upgrade_type + "']").removeClass("bc_waiting")
    $("[data-upgrade-type2='" + upgrade_type + "']").removeClass("bc_waiting")
}

//update_ux_from_server()


consumer.subscriptions.create({channel: "UserChannel", user_id: user_id, ws_token: ws_token}, {
    connected() {
    },

    disconnected() {
    },

    received(data) {
        if (typeof get_cashback === 'function')
            get_cashback()
        if (data.ws_type === "new_rank_data" && window.location.pathname.includes(data.url)) {
            toastr_wrapper({
                "toast_type": "success",
                "title": "There is new Data available. ",
                "text": "Click here to reload Now",
                "click": function () {
                    if (data.modern) {
                        $(".format_switch.modern").removeClass("btm-secondary")
                        $(".format_switch.wild").addClass("btm-secondary")
                    } else {
                        $(".format_switch.wild").removeClass("btm-secondary")
                        $(".format_switch.modern").addClass("btm-secondary")
                    }
                    render_chart()
                    render_card_stats()
                }
            })
        } else if (data.ws_type === "go_refresh_vip") {
            if ($(".new_modal_nav_nav .inv_btn_vip.active").length > 0) {
                $(".inv_btn_vip.active").click()
            }
            update_ux_from_server("claim_passes")
            sign_settings = data.vip
        } else if (data.ws_type === "reload_curr") {
            reload_currency()
        } else if (data.ws_type === "go_quest") {
            reload_quest_log()
        } else if (data.ws_type === "go_achievement") {
            get_achievements()
        } else if (data.ws_type === "go_weekly") {
            reload_quest_log()
            update_ux_from_server()
        } else if (data.ws_type === "alert") {
            toastr_wrapper(data.data)
            get_notifications()
            if (data.page === "prime_manage" && window.location.pathname === "/prime/manage") {
                refresh_prime()
            } else if (data.page === "prime" && window.location.pathname === "/prime") {
                $(".loading-prime, .loading-omega, .loading-trial").addClass("active")
                reload_after_buy()
                render_balances2()
            }

        } else if (data.ws_type === "modal") {
            setTimeout(function () {
                get_surprise_modal()
            }, 500)


        } else if (data.ws_type === "reward_unlocked") {
            $(".unlock_single[data-id='" + data.id + "']").closest(".col-6").remove()
            if ($(".unlock_single").length === 0)
                $(".unlock_no").removeClass("d-none")
            $(".unlock_menu.active").click()
            setTimeout(function () {
                update_ux_from_server()
                get_surprise_modal()
            }, 350)

        } else if (data.ws_type === "go_claim") {
            go_stats = data.stats
            update_go()
        } else if (data.ws_type === "new_comp") {
            soulbound_part = data.balance_new

            update_soulbound_part()
            update_part()
        } else if (data.ws_type === "new_part") {
            db_part = data.balance_new

            update_soulbound_part()
            update_part()
        } else if (data.ws_type === "match_started") {
            enemy_found(data.mode, 1, data.player_1, data.player_2)
        } else if (data.ws_type === "match_resolved") {
            show_battle(data.bm_id)
        } else if (data.ws_type === "reload_mm") {
            $(".matchmaking.active").click()
        } else if (data.ws_type === "match_surrendered") {
            if($(".pick_cont").length > 0 && last_bm_id === data.bm_id) {
                last_bm_id = null
                $(".matchmaking.active").click()
            }
            console.log("match_surrendered click")
        } else if (data.ws_type === "bought_token") {
            update_ux_from_server()
            $(".new_modal_nav_nav .swap_currency.active").click()
        } else if (data.ws_type === "new_shard") {
            db_shard = data.balance_new
            update_soulbound_part()
            update_part()
        } else if (data.ws_type === "new_anima") {
            db_anima = data.balance_new
            checkAnimaRequirements()
            update_soulbound_part()
            update_part()
        } else if (data.ws_type === "new_aether") {
            soulbound_anima = data.balance_new
            update_soulbound_part()
            update_part()
        } else if (data.ws_type === "new_pyrite") {
            $(".replace_me_pyrite").html(data.balance_new)
        } else if (data.ws_type === "go_refresh_data") {
            update_go()
        } else if (data.ws_type === "go_log") {
            add_go_log(data.html, data.id)
            if (data.trx_id !== undefined && $(".go_activity_log").length > 0)
                unwait(data.trx_id)
            if (data.activity_log_type !== undefined && data.activity_log_type === "activated")
                window.location.reload()
            //update_ux_from_server()
        } else if (data.ws_type === "airdrop_points") {

            $(".replace_me_aidrop").html(data.points)
        } else if (data.ws_type === "bm_reward_notification") {
            bm_notifications.push(data)
            setTimeout(function () {
                bm_notification_interval = setInterval(function () {

                    show_conquest_notifications()
                }, 3000)
                update_conquest_rewards()
            }, 3000)

        } else if (data.ws_type === "go_challenge") {

            toastr_wrapper({
                "toast_type": data.toast_type,
                "text": data.text
            })
            $(".matchmaking.active").click()
            check_battle_widget()
        } else if (data.ws_type === "go_notification") {
            toastr_wrapper({
                "toast_type": data.toast_type,
                "text": data.text
            })

            if (data.upgrade_type !== undefined)
                remove_waiting(data.upgrade_type)
            if (data.reload_prepaid !== undefined)
                get_prepaid()
            if (data.reload_pass !== undefined)
                reload_pass(false)
            if (data.target !== undefined) {
                $(data.target).replaceWith(data.render)
                if (data.page === "inventory") {
                    bind_inventory()
                    $(".inventory_search_btn").click()
                }
            }
        } else if (data.ws_type === "go_remove_from_inventory") {
            $(data.target).remove()
        } else if (data.ws_type === "go_refresh_equip") {
            $(".equip_wrapper").replaceWith(data.render)
            bind_inventory()
        }
    }
});


consumer.subscriptions.create({channel: "UserChannel", user_id: 0, token: ""}, {
    connected() {

    },

    disconnected() {
    },

    received(data) {
        if (data.ws_type === "go_refresh_data") {
            update_go()
        } else if (data.type === "gate_opened") {
            toastr_wrapper({
                "toast_type": "success",
                "title": "The Gates of Conquest Opened",
                "text": "Seize your chance for glory in Conquest's ranked battles and earn some extra ANIMA and XP!<br>The arena is now open to all challengers!<br><br>Act fast – the Gates of Conquest close in one hour!",
                timeOut: 0
            })
            reload_mm()
            update_ux_from_server("bottom_nav")
        } else if (data.type === "gate_closed") {
            toastr_wrapper({
                "toast_type": "success",
                "title": "The Gates of Conquest Closed",
                "text": "The Gates of Conquest are closing!<br>A heroic salute to all brave testers who joined.<br><br>Your valor and feedback have forged the path to glory.<br>We'll return stronger – until then, sharpen your skills!",
            })
            reload_mm()
            update_ux_from_server("bottom_nav")
        }
        if (data.type === "restarted") {
            alert("restarted")
        }
    }
});
